import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { addDays, format } from 'date-fns';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { LandingStore } from 'src/app/core/state/landing/landing.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { LandingState, WidgetInfoModel, WidgetTypes } from 'src/app/shared/models/landing.model';
import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { SportModel } from 'src/app/modules/sport/models/todays-events/todays-events.model';
import { SportsOddsBoostService } from 'src/app/core/services/sports/sports-odds-boost.service';
import { EventSummaryModel } from 'src/app/shared/models/sport.model';
import { LandingQuery } from 'src/app/core/state/landing/landing.query';
import { SortFilterOptionModel, getDateFromDayName } from 'src/app/shared/models/filter-sort.model';
import { APIService } from './api.service';
import { SportService } from './sports/sport.service';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  private readonly endpoints = {
    LIVE_BETTING: 'api/feeds/live/mostpopular',
    LIVE_BETTING_AVAILAIBLE_SPORTS: 'api/feeds/live/haspopular',
    LIVE_BETTING_NEW: 'api/feeds/live/popularfixtures',
    BET_BUILDER: 'api/feeds/prematch/popularboostedmarkets',
    MOST_POPULAR: 'api/feeds/prematch/mostpopular',
    MOST_POPULAR_INCLUDE_LIVE: 'api/feeds/prematch/popularfixtures',
    UPCOMING: 'api/feeds/prematch/lastminute',
    TODAY: 'api/feeds/prematch/GetPagedEvents',
  };
  private liveBettingInterval;
  private liveBettingNewInterval;
  private betBuilderInterval;
  private mostPopularInterval;
  private pauseMostPopularInterval;
  private upcomingEventsInterval;
  private pauseUpcomingEventsInterval;
  private todayEventsInterval;
  private pauseTodayEventsInterval;
  private oddsBoostEventsInterval;
  private pauseOddsBoostEventsInterval;

  readonly widgetTypes = WidgetTypes;

  constructor(
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly landingStore: LandingStore,
    private readonly sportsOddsBoostService: SportsOddsBoostService,
    private readonly sportsService: SportService,
    private readonly landingQuery: LandingQuery
  ) {}

  stopAutoUpdate(): void {
    clearInterval(this.liveBettingInterval);
    clearInterval(this.liveBettingNewInterval);
    clearInterval(this.betBuilderInterval);
    clearInterval(this.mostPopularInterval);
    clearInterval(this.upcomingEventsInterval);
    clearInterval(this.todayEventsInterval);
    clearInterval(this.oddsBoostEventsInterval);
  }

  pauseIntervals(targetWidget: WidgetTypes): void {
    this.pauseMostPopularInterval = true;
    this.pauseUpcomingEventsInterval = true;
    this.pauseTodayEventsInterval = true;
    this.pauseOddsBoostEventsInterval = true;

    switch (targetWidget) {
      case this.widgetTypes.MostPopular:
        this.pauseMostPopularInterval = false;
        break;
      case this.widgetTypes.Upcoming:
        this.pauseUpcomingEventsInterval = false;
        break;
      case this.widgetTypes.Today:
        this.pauseTodayEventsInterval = false;
        break;
      case this.widgetTypes.OddsBoost:
        this.pauseOddsBoostEventsInterval = false;
        break;
      default:
        break;
    }
  }

  clear(): void {
    this.landingStore.updateUI({
      liveBettingEventsLoading: false,
      liveBettingNewEventsLoading: false,
      betBuilderEventsLoading: false,
      mostPopularEventsLoading: false,
      upcomingEventsLoading: false,
      todayEventsLoading: false,
      oddsBoostEventsLoading: false,
    });

    this.landingStore.update({
      liveBettingEvents: undefined,
      liveBettingNewEvents: undefined,
      betBuilderEvents: undefined,
      mostPopularEvents: undefined,
      upcomingEvents: undefined,
      todayEvents: undefined,
      oddsBoostEvents: undefined,
    });
  }

  getWidgetsInfo(): void {
    const fallbackTimer = this.appConfigService.get('fallbackTimer');
    const callStarted = this.applicationService.getEpochTime();

    const waitForApiResponse = window.setInterval(() => {
      const callStartedDiff = this.applicationService.getEpochTime() - callStarted;
      const isTimeOver = !(callStartedDiff < fallbackTimer);
      if (isTimeOver) {
        this.getWidgetsInfoFromConfig();
        clearInterval(waitForApiResponse);
      }
    }, 1000);

    this.apiService
      .get(APIType.CMS, `SiteRoot/GetWidgetsList`)
      .pipe(
        catchError(err => {
          this.getWidgetsInfoFromConfig();
          return throwError(err);
        }),
        tap((widgetsInfo: WidgetInfoModel[]) => {
          this.applicationService.updateCms({ lastLandingPageWidgetsUpdate: this.applicationService.getEpochTime() });
          if (widgetsInfo && widgetsInfo.length > 0) {
            this.landingStore.update({ widgetsInfo });
          }
        }),
        finalize(() => {
          clearInterval(waitForApiResponse);
        })
      )
      .subscribe();
  }

  getLiveBettingEvents(language: string, sportId: number, numberOfEvents: number, refreshInterval: number): void {
    this.landingStore.updateUI({ liveBettingEventsLoading: true });
    this.getLiveBettingEventsCall(language, sportId, numberOfEvents)
      .pipe(first())
      .subscribe(_ => {
        this.landingStore.updateUI({ liveBettingEventsLoading: false });
      });

    // auto-update
    this.liveBettingInterval = window.setInterval(() => {
      this.getLiveBettingEventsCall(language, sportId, numberOfEvents).pipe(first()).subscribe();
    }, refreshInterval);
  }

  getLiveBettingNewEvents(
    language: string,
    sportId: number,
    numberOfEvents: number,
    refreshInterval: number,
    widgetSportIds: number[]
  ): void {
    clearInterval(this.liveBettingNewInterval);

    this.landingStore.updateUI({ liveBettingNewEventsLoading: true });
    this.getLiveBettingNewEventsCall(language, sportId, numberOfEvents, widgetSportIds)
      .pipe(first())
      .subscribe(_ => {
        this.landingStore.updateUI({ liveBettingNewEventsLoading: false });
      });

    // auto-update
    this.liveBettingNewInterval = window.setInterval(() => {
      this.getLiveBettingNewEventsCall(language, sportId, numberOfEvents, widgetSportIds).pipe(first()).subscribe();
    }, refreshInterval);
  }

  getBetBuilderEvents(language: string, numberOfEvents: number, refreshInterval: number): void {
    this.landingStore.updateUI({ betBuilderEventsLoading: true });
    this.getBetBuilderEventsCall(language, numberOfEvents)
      .pipe(first())
      .subscribe(_ => {
        this.landingStore.updateUI({ betBuilderEventsLoading: false });
      });

    // auto-update
    this.betBuilderInterval = window.setInterval(() => {
      this.getBetBuilderEventsCall(language, numberOfEvents).pipe(first()).subscribe();
    }, refreshInterval);
  }

  getMostPopularEvents(
    language: string,
    sportId: number,
    numberOfEvents: number,
    refreshInterval: number,
    includeLiveEvents: boolean
  ): void {
    clearInterval(this.mostPopularInterval);
    this.landingStore.updateUI({ mostPopularEventsLoading: true });
    this.getMostPopularEventsCall(language, sportId, numberOfEvents, new Date(Date.now()), includeLiveEvents)
      .pipe(first())
      .subscribe(_ => {
        this.landingStore.updateUI({ mostPopularEventsLoading: false });
      });

    // auto-update
    this.mostPopularInterval = window.setInterval(() => {
      if (!this.pauseMostPopularInterval) {
        this.getMostPopularEventsCall(language, sportId, numberOfEvents, new Date(Date.now()), includeLiveEvents).pipe(first()).subscribe();
      }
    }, refreshInterval);
  }

  getUpcomingEvents(language: string, sportId: number, numberOfEvents: number, refreshInterval: number): void {
    clearInterval(this.upcomingEventsInterval);

    this.landingStore.updateUI({ upcomingEventsLoading: true });
    this.getUpcomingEventsCall(language, sportId, numberOfEvents)
      .pipe(first())
      .subscribe(() => {
        this.landingStore.updateUI({ upcomingEventsLoading: false });
      });

    // auto-update
    this.upcomingEventsInterval = window.setInterval(() => {
      if (!this.pauseUpcomingEventsInterval) {
        this.getUpcomingEventsCall(language, sportId, numberOfEvents).pipe(first()).subscribe();
      }
    }, refreshInterval);
  }

  getTodayEvents(language: string, sportId: number, numberOfEvents: number, refreshInterval: number): void {
    clearInterval(this.todayEventsInterval);

    this.landingStore.updateUI({ todayEventsLoading: true });
    this.getTodayEventsCall(language, sportId, numberOfEvents)
      .pipe(first())
      .subscribe(() => {
        this.landingStore.updateUI({ todayEventsLoading: false });
      });

    // auto-update
    this.todayEventsInterval = window.setInterval(() => {
      if (!this.pauseTodayEventsInterval) {
        this.getTodayEventsCall(language, sportId, numberOfEvents).pipe(first()).subscribe();
      }
    }, refreshInterval);
  }

  getOddsBoostEvents(language: string, sportId: number, refreshInterval: number): void {
    clearInterval(this.oddsBoostEventsInterval);

    this.landingStore.updateUI({ oddsBoostEventsLoading: true });
    this.getOddsBoostEventsCall(sportId)
      .pipe(first())
      .subscribe(() => {
        this.landingStore.updateUI({ oddsBoostEventsLoading: false });
      });

    // auto-update
    this.oddsBoostEventsInterval = window.setInterval(() => {
      if (!this.pauseOddsBoostEventsInterval) {
        this.getOddsBoostEventsCall(sportId).pipe(first()).subscribe();
      }
    }, refreshInterval);
  }

  getMenuItems(): void {
    const fallbackTimer = this.appConfigService.get('fallbackTimer');
    const callStarted = this.applicationService.getEpochTime();

    const waitForApiResponse = window.setInterval(() => {
      const callStartedDiff = this.applicationService.getEpochTime() - callStarted;
      const isTimeOver = !(callStartedDiff < fallbackTimer);
      if (isTimeOver) {
        this.getMenuItemsFromConfig();
        clearInterval(waitForApiResponse);
      }
    }, 1000);

    this.apiService
      .get(APIType.CMS, `SiteRoot/GetLandingPageMenuItems`)
      .pipe(
        catchError(err => {
          this.getMenuItemsFromConfig();
          return throwError(err);
        }),
        tap(menuItems => {
          this.applicationService.updateCms({ lastLandingPageMenuItemsUpdate: this.applicationService.getEpochTime() });
          if (menuItems && menuItems.length > 0) {
            const appMenuItems = Capacitor.isNativePlatform() ? menuItems.filter(x => x.linkURL !== '/download-app') : menuItems;
            this.landingStore.update({ menuItems: appMenuItems });
          }
        }),
        finalize(() => {
          clearInterval(waitForApiResponse);
        })
      )
      .subscribe();
  }

  updateUI(ui: Partial<LandingState['ui']>): void {
    this.landingStore.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateSelectedDate(selectedDate: SortFilterOptionModel): void {
    selectedDate.value = getDateFromDayName(selectedDate.name);
    this.landingStore.updateSelectedDate(selectedDate);
  }

  updateSelectedSortType(sortType: SortFilterOptionModel): void {
    this.landingStore.updateSortType(sortType);
  }

  private getWidgetsInfoFromConfig(): void {
    this.landingStore.update({
      widgetsInfo: this.appConfigService.get('sports').widgetDefaults.landingPage,
    });
  }

  private getMenuItemsFromConfig(): void {
    this.landingStore.update({
      menuItems: this.appConfigService.get('menuDefaults').landingPage.map(mi => new MenuItemModel(mi)),
    });
  }

  private getLiveBettingEventsCall(language: string, sportId: number, numberOfEvents: number): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService
      .get(APIType.SportsbookFeed, `${this.endpoints.LIVE_BETTING}/${sportId}/${numberOfEvents}/${language}`, apiSettings)
      .pipe(
        map((responseData: any) => {
          this.landingStore.update({
            liveBettingEvents: this.sportsService.mapLiveMatchSummaryDataToModel(responseData),
          });
          this.landingStore.setError(undefined);
        }),
        catchError((err: HttpErrorResponse) => {
          this.landingStore.setError(err);
          return throwError(err.message);
        })
      );
  }

  private getLiveBettingNewEventsCall(
    language: string,
    sportId: number,
    numberOfEvents: number,
    widgetSportIds: number[]
  ): Observable<any> {
    let availableSportsList;
    return this.liveBettingNewAvailableSportsList(widgetSportIds).pipe(
      switchMap((postResponse: any) => {
        const hasSportAvailable = postResponse?.some(sport => sport.HasEvents);
        if (hasSportAvailable) {
          availableSportsList = postResponse.filter(sport => sport.HasEvents)?.map(sport => sport.Id);
          const foundSport = postResponse.find(sport => sport.Id === sportId);
          const selectedSportId = foundSport && foundSport.HasEvents ? sportId : postResponse.find(item => item.HasEvents)?.Id;

          return this.liveBettingNewMostPopularEvents(language, selectedSportId, numberOfEvents);
        }
        return of(undefined);
      }),
      map((responseData: any) => {
        this.landingStore.update({
          liveBettingNewEvents: this.sportsService.mapMatchSummaryDataToModel(
            responseData?.AreaMatches[0],
            false,
            true,
            availableSportsList
          ),
        });
        this.landingStore.setError(undefined);
      }),
      catchError((err: HttpErrorResponse) => {
        this.landingStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  private liveBettingNewAvailableSportsList(model: any): Observable<any> {
    return this.apiService.post(APIType.SportsbookFeed, this.endpoints.LIVE_BETTING_AVAILAIBLE_SPORTS, model);
  }

  private liveBettingNewMostPopularEvents(language: string, sportId: number, numberOfEvents: number): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(
      APIType.SportsbookFeed,
      `${this.endpoints.LIVE_BETTING_NEW}/${language}/${sportId}/${numberOfEvents}`,
      apiSettings
    );
  }

  private getBetBuilderEventsCall(language: string, numberOfEvents: number): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    const marketTypeId = this.appConfigService.get('sports').betBuilderMarketTypeID;

    return this.apiService.get(APIType.SportsbookFeed, `${this.endpoints.BET_BUILDER}/${marketTypeId}/${numberOfEvents}`, apiSettings).pipe(
      map((responseData: any) => {
        this.landingStore.update({
          betBuilderEvents: this.sportsService.mapBoostedOddsDataToModel(responseData, marketTypeId),
        });
        this.landingStore.setError(undefined);
      }),
      catchError((err: HttpErrorResponse) => {
        this.landingStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  private getMostPopularEventsCall(
    language: string,
    sportId: number,
    numberOfEvents: number,
    date: Date,
    includeLiveEvents: boolean
  ): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    let apiPath = this.endpoints.MOST_POPULAR;
    if (includeLiveEvents) {
      apiPath = this.endpoints.MOST_POPULAR_INCLUDE_LIVE;
    }

    const requestForNextDayIfEmpty = (res: any) =>
      res && res.AreaMatches[0] && res.AreaMatches[0].Items && res.AreaMatches[0].Items.length > 0
        ? of(res)
        : this.apiService.get(
            APIType.SportsbookFeed,
            `${apiPath}/${language}/${sportId}/${numberOfEvents}/${format(addDays(new Date(date), 1), 'yyyy-MM-dd')}`,
            apiSettings
          );

    return this.apiService
      .get(
        APIType.SportsbookFeed,
        `${apiPath}/${language}/${sportId}/${numberOfEvents}/${format(new Date(date), 'yyyy-MM-dd')}`,
        apiSettings
      )
      .pipe(
        switchMap(requestForNextDayIfEmpty),
        map((responseData: any) => {
          this.landingStore.update({
            mostPopularEvents: this.sportsService.mapMatchSummaryDataToModel(responseData.AreaMatches[0], false, true),
          });
          this.landingStore.setError(undefined);
        }),
        catchError((err: HttpErrorResponse) => {
          this.landingStore.setError(err);
          this.landingStore.updateUI({ mostPopularEventsLoading: false });
          return throwError(err.message);
        })
      );
  }

  private getUpcomingEventsCall(language: string, sportId: number, numberOfEvents: number): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService
      .get(APIType.SportsbookFeed, `${this.endpoints.UPCOMING}/${language}/${sportId}/${numberOfEvents}`, apiSettings)
      .pipe(
        map((responseData: any) => {
          this.landingStore.update({
            upcomingEvents: this.sportsService.mapMatchSummaryDataToModel(responseData.AreaMatches[0]),
          });
          this.landingStore.setError(undefined);
        }),
        catchError((err: HttpErrorResponse) => {
          this.landingStore.setError(err);
          this.landingStore.updateUI({ upcomingEventsLoading: false });
          return throwError(err.message);
        })
      );
  }

  private getTodayEventsCall(language: string, sportId: number, numberOfEvents: number): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const DATE = this.landingQuery.selectedDate?.value || format(new Date(), 'yyyy-MM-dd');
    const areaId = 0;
    const regionId = 0;
    const API = `api/feeds/prematch/GetPagedEvents/${language}/${DATE}/${areaId}/${regionId}/${sportId}/0/${numberOfEvents}`;
    return this.apiService.get(APIType.SportsbookFeed, API, apiSettings).pipe(
      map((responseData: any) => {
        const sports = [];
        responseData.Sports.forEach(sport => {
          const newSport = new SportModel({
            sportId: sport.SportID,
            sportName: sport.SportName,
          });
          sports.push(newSport);
        });

        this.landingStore.update({
          todaySports: sports,
          todayEvents: this.sportsService.mapMatchSummaryDataToModel(responseData.AreaMatches[0], false, true),
        });
        this.landingStore.setError(undefined);
      }),
      catchError((err: HttpErrorResponse) => {
        this.landingStore.setError(err);
        this.landingStore.updateUI({ todayEventsLoading: false });
        return throwError(err.message);
      })
    );
  }

  private getOddsBoostEventsCall(sportId: number): Observable<void> {
    return this.sportsOddsBoostService.retrieveOddsBoostData(sportId).pipe(
      map((oddsBoostEvents: EventSummaryModel) => {
        this.landingStore.update({
          oddsBoostEvents,
        });
        this.landingStore.setError(undefined);
      }),
      catchError((err: HttpErrorResponse) => {
        this.landingStore.setError(err);
        this.landingStore.updateUI({ oddsBoostEventsLoading: false });
        return throwError(err.message);
      })
    );
  }
}
