import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { differenceInMilliseconds, parseISO } from 'date-fns';

import { SportStore } from 'src/app/core/state/sport/sport.store';

import {
  AreaModel,
  CategoryModel,
  EventSummaryModel,
  FlattenedSportModel,
  MarketModel,
  MatchModel,
  PlayerViewModel,
  RegionModel,
  SportModel,
  SportQuicklinkModel,
  SportState,
  TournamentModel,
} from 'src/app/shared/models/sport.model';
import { groupBy, kebabCase, keys } from 'lodash-es';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class SportQuery extends Query<SportState> {
  selectedPrematch$ = this.select(store => store.selectedPrematch);

  sportsList$ = this.select(store => store.sportsList);
  sportsQuicklinks$ = this.select(store => store.sportsQuicklinks);
  isQuicklinksLoading$ = this.select(store => store.isQuicklinksLoading);

  // Event selection
  selectedSport$ = this.select(store => store.eventSelection.selectedSport);
  isSportsListOpened$ = this.select(store => store.eventSelection.isSportsListOpened);
  selectedQuicklink$ = this.select(store => store.eventSelection.selectedQuicklink);
  eventSelectionDepth$ = this.select(store => store.eventSelection.eventSelectionDepth);
  selectedMarket$ = this.select(store => store.eventSelection.selectedMarket);
  selectedArea$ = this.select(store => store.eventSelection.selectedArea);
  selectedAreaId$ = this.select(store => store.eventSelection.selectedAreaId);
  areaMarkets$ = this.select(store => store.eventSelection.areaMarkets);
  topCompetitions$ = this.select(store => store.eventSelection.topCompetitions);
  competitionsAZ$ = this.select(store => store.eventSelection.competitionsAZ);
  allCompetitionByCountry$ = this.select(store => store.eventSelection.allCompetitionByCountry);
  outrights$ = this.select(store => store.eventSelection.outrights);
  selectedSportOutrights$ = this.select(store =>
    store.eventSelection.outrights?.filter(outright => outright.id === store.eventSelection.selectedSport.id)
  );
  specialSports$ = this.select(store => store.eventSelection.specialSports);
  selectedSpecialSports$ = this.select(store =>
    store.eventSelection.specialSports.filter(s => s.id === store.eventSelection.selectedSport.id)
  );
  playerSpecialsSport$ = this.select(store => store.eventSelection.playerSpecialsSport);
  areaAndRegionCache$ = this.select(store => store.eventSelection.areaAndRegionCache);
  restoreAreaRegionsCache$ = this.select(store => store.eventSelection.restoreAreaRegionsCache);
  eventSelectionLoaded$ = this.select(store => store.eventSelection.eventSelectionLoaded);
  autoForwardHappened$ = this.select(store => store.eventSelection.autoForwardHappened);
  isPlayerArea$ = this.selectedPrematch$.pipe(map(preMatch => (preMatch ? preMatch[0]?.groupingType === 2 : false)));
  oddsBoostInfoModalCMSContent$ = this.select(store => store.oddsBoostInfoModalCMSContent);
  marketTypeIds$ = this.select(store => store.marketTypeIds);

  allCompetitionByCountrySelectedElements$ = this.select(store => store.eventSelection.allCompetitionByCountry).pipe(
    map(data => {
      if (!data || !this.selectedSport) {
        return;
      }
      const dataFilteredBySport = data.filter(sport => sport.id === this.selectedSport.id)[0];

      return dataFilteredBySport ? dataFilteredBySport.categories.filter(category => category.selectedInView) : undefined;
    })
  );

  outrightsSelectedElements$ = this.select(store => store.eventSelection.outrights).pipe(
    map(data => {
      if (!data) {
        return;
      }

      const dataFilteredBySport = data.filter(sport => sport.id === this.selectedSport.id)[0];

      return dataFilteredBySport ? dataFilteredBySport.categories.filter(category => category.selectedInView) : undefined;
    })
  );

  specialSportsSelectedElements$ = this.select(store => store.eventSelection.specialSports).pipe(
    map(data => this.mapSpecialSportSelections(data))
  );

  playerSpecialsSportSelectedElements$ = this.select(store => store.eventSelection.playerSpecialsSport).pipe(
    map(data => this.mapSpecialSportSelections(data))
  );

  playerSpecialsSelectedElementsDepth2$ = this.select(store => store.eventSelection.playerSpecialsSport).pipe(
    map(data => this.mapSpecialSportSelectionsDepthTwo(data))
  );

  // Prematch
  selectedPlayerIds$ = this.select(store => store.selectedPlayerIds);
  isItCorrectScore$ = this.select(store => store.isItCorrectScore);
  areas$ = this.select(store => store.areas);
  regions$ = this.select(store => store.regions);
  loading$ = this.selectLoading();
  error$ = this.selectError();
  favouriteSportIds$ = this.select(state => state.favouriteSports);
  favouriteSports$: Observable<SportModel[]>;
  showTabsAndBreadcrumbChildren$ = this.selectedSport$.pipe(
    filter(selectedSport => !!selectedSport),
    map(selectedSport => !this.appConfigService.get('sports').hideTabsForSports.includes(selectedSport.id))
  );

  // Odds Boost
  oddsBoostSportSummary$ = this.select(state => state.oddsBoostSportData);
  oddsBoostSportMatchesByDate$ = this.oddsBoostSportSummary$.pipe(
    filter(summary => !!summary),
    map(summary =>
      groupBy(
        summary.matches
          .filter(match => match.odds.some(odd => odd?.marketTypeId === summary.marketSelected.id))
          .sort((a, b) => differenceInMilliseconds(parseISO(a.date.toString()), parseISO(b.date.toString()))),
        match => {
          // Compare only date not time
          const matchDate = new Date(match.date);
          return new Date(matchDate.getFullYear(), matchDate.getMonth(), matchDate.getDate()).toISOString();
        }
      )
    )
  );
  oddsBoostSportMatchesDates$ = this.oddsBoostSportMatchesByDate$.pipe(map(matchesByDate => keys(matchesByDate)));
  oddsBoostSportSummaryMarkets$ = this.oddsBoostSportSummary$.pipe(
    filter(summary => !!summary),
    map(summary => summary.area.markets)
  );

  // Breadcrumb
  openedBreadcrumbSection$ = this.select(state => state.ui.openedBreadcrumbSection);

  constructor(protected store: SportStore, private readonly appConfigService: AppConfigService) {
    super(store);

    this.favouriteSports$ = combineLatest([this.favouriteSportIds$, this.sportsList$]).pipe(
      filter(([favs, sports]) => !!sports),
      map(this.mapIdsToSports),
      map(this.sortSports)
    );
  }

  get sportsList(): SportModel[] {
    return this.getValue().sportsList;
  }

  get sportsListBreadcrumbItems(): SportModel[] {
    return this.getValue().sportsList?.map(item => ({
      ...item,
      url: `/sports/${kebabCase(item.name)}`,
    }));
  }

  get sportsQuicklinks(): SportQuicklinkModel[] {
    return this.getValue().sportsQuicklinks;
  }

  get isQuicklinksLoading(): boolean {
    return this.getValue().isQuicklinksLoading;
  }

  get areaAndRegionCache(): { areas: AreaModel[]; regions: RegionModel[]; visible: boolean }[] {
    return this.getValue().eventSelection.areaAndRegionCache;
  }

  get areas(): AreaModel[] {
    return this.getValue().areas;
  }

  get autoForwardHappened(): boolean {
    return this.getValue().eventSelection.autoForwardHappened;
  }

  get regions(): RegionModel[] {
    return this.getValue().regions;
  }

  get restoreAreaRegionsCache(): boolean {
    return this.getValue().eventSelection.restoreAreaRegionsCache;
  }

  get selectedArea(): AreaModel {
    return this.getValue().eventSelection.selectedArea;
  }

  get selectedMarket(): MarketModel {
    return this.getValue().eventSelection.selectedMarket;
  }

  get selectedAreaId(): number {
    return this.getValue().eventSelection.selectedAreaId;
  }

  get topCompetitions(): MatchModel[] {
    return this.getValue().eventSelection.topCompetitions;
  }

  get areaMarkets(): MarketModel[] {
    return this.getValue().eventSelection.areaMarkets;
  }

  get selectedPrematch(): EventSummaryModel[] {
    return this.getValue().selectedPrematch;
  }

  get competitionsAZ(): FlattenedSportModel[] {
    return this.getValue().eventSelection.competitionsAZ;
  }

  get allCompetitionByCountry(): SportModel[] {
    return this.getValue().eventSelection.allCompetitionByCountry;
  }

  get outrights(): SportModel[] {
    return this.getValue().eventSelection.outrights;
  }

  get specialSports(): SportModel[] {
    return this.getValue().eventSelection.specialSports;
  }

  get playerSpecialsSport(): SportModel[] {
    return this.getValue().eventSelection.playerSpecialsSport;
  }

  get isSportsListOpened(): boolean {
    return this.getValue().eventSelection.isSportsListOpened;
  }

  get selectedSport(): SportModel {
    return this.getValue().eventSelection.selectedSport;
  }

  get selectedQuicklink(): SportQuicklinkModel {
    return this.getValue().eventSelection.selectedQuicklink;
  }

  get selectedPlayerIds(): number[] {
    return this.getValue().selectedPlayerIds;
  }

  get favouriteSportIds(): number[] {
    return this.getValue().favouriteSports;
  }

  get eventSelectionDepth(): number {
    return this.getValue().eventSelection.eventSelectionDepth;
  }

  get hasPlayersData(): boolean {
    return !!this.getValue().playersData.length;
  }

  get playersData(): PlayerViewModel[] {
    return this.getValue().playersData;
  }

  get oddsBoostSportSummary(): EventSummaryModel {
    return this.getValue().oddsBoostSportData;
  }

  selectIsFavourite(sportId: number): Observable<boolean> {
    return this.select(store => store.favouriteSports.indexOf(sportId) > -1);
  }

  getOutrightSportIndex(sportId: number): number {
    return this.getValue().eventSelection.outrights.findIndex(outright => outright.id === sportId);
  }

  private readonly mapIdsToSports = ([ids, sports]: [number[], SportModel[]]): SportModel[] => {
    const favourites: SportModel[] = [];

    if (ids) {
      ids.forEach(id => {
        const sport = sports.find(s => s.id === id);
        sport && favourites.push(sport);
      });
    }

    return favourites;
  };

  private readonly sortSports = (sports: SportModel[]): SportModel[] => {
    const sorted = [...sports];

    sorted.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    for (let i = 0; i < sorted.length; i++) {
      if (sorted[i].name === 'Football') {
        const football = sorted.splice(i, 1);
        sorted.unshift(...football);
        break;
      }
    }

    return sorted;
  };

  private mapSpecialSportSelections(data: SportModel[]): CategoryModel[] {
    if (!data) return;

    const selectedCategories = [];
    data.forEach(sport => {
      const selected = sport.categories.filter(category => category.selectedInView);
      if (selected.length) {
        selectedCategories.push(...selected);
      }
    });

    return selectedCategories;
  }

  private mapSpecialSportSelectionsDepthTwo(data: SportModel[]): TournamentModel[] {
    if (!data) return;

    const selectedTournaments = [];
    data.forEach(sport => {
      const selected = sport.categories.filter(category => category.selectedInView);
      if (selected.length) {
        selected.forEach(category => {
          category.tournaments.filter(tournament => {
            if (tournament.selectedInView) {
              selectedTournaments.push(tournament);
            }
          });
        });
      }
    });

    return selectedTournaments;
  }
}
