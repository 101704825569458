// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  appConfigPath: 'assets/configs/app.test.conf',
  // brandMap is used to enable the isBrand() functionality inside application.query.ts. Must be kept up to date with new brands
  brandMap: {
    nigeria: 1901,
    ethiopia: 1902,
    kenya: 1903,
    ghana: 1904,
  },
  // The below default settings will be used if a value is not defined in the brand's config file OR if the config file is not available
  appConfigDefaults: {
    siteVersion: '1.0.0',
    brandId: 1901,
    f2pBrandId: 1999,
    activitySource: 'mobileplus',
    appActivitySource: 'mobileandroid',
    apiBaseUrl: {
      cms: 'https://cmsapi.betking.com/api/mobile',
      guardian: 'https://auth-api-betagy-sbrc.betagy.co',
      f2pGuardian: 'https://auth-api-betagy-f2p-lotdev1.betagy.co',
      platform: 'https://website-api-betking-sbrc.betagy.co',
      website: 'https://frontendservices-pldev1.betagy.co',
      engagement: 'https://sports-eng.int.kingmakers.tech/api/engagement',
      sportsbook: 'https://sportsapi-sbrc.betagy.co/api',
      sportsbookBetting: 'https://betting-api-sbrc.int.kingmakers.tech/api/sports/v1',
      sportsbookCashout: 'https://cashout-api-sbrc.kingmakers.tech/api/v1',
      sportsbookFeed: 'https://sportsapi-sbrc.betagy.co',
      sportsbookWebSocket: 'https://st-push-test-sbrc1.kingmakers.tech',
      sportsbookVslFeed: 'https://api.vslinternal9522.com',
      sportsbookOffer: 'https://st-feeds-offer-api-dev.int.kingmakers.tech',
      sportsbookVslWidgets: 'https://d2o615e7pmr1fr.cloudfront.net/main.js',
      sportsbookBetGeniusWidget: 'https://gsm-widgets.betstream.betgenius.com/multisportgametracker',
      sportsbookBetBuilderWidget: 'https://kingmakers-uat-gen2.sportcastlive.com/markets',
      betRadarStatistics: 'https://s5.sir.sportradar.com/betking',
      betRadarResults: 'https://ls.fn.sportradar.com/betking',
      betRadarTeamLogo: 'https://img.sportradar.com/ls',
      betRadarWidgets: 'https://widgets.sir.sportradar.com/8291e456ef20ffd848b311f769db64c9/widgetloader',
      virtuals: 'https://virtualsapi-vrdev2.betagy.co',
      virtualsBetSearch: 'https://virtualsapi-vrdev2.betagy.co',
      virtualsFeed: 'https://virtualsapi-feeds-vrdev2.betagy.co',
      virtualsWebSocket: 'wss://virtualsapi-sockets-vrdev2.betagy.co/WebSockets/MobileNotifier.ashx',
      kmJackpots: 'https://virtualsapi-feeds-vrdev2.betagy.co',
      jackpotBets: 'https://lottery-api-jpbdev1.betagy.co',
      strapiCms: 'https://bff-gateway-staging.kingmakers.workers.dev/api/v1/strapi/betking-cms',
      superPicksValidationDomain: 'https://frontendservices-f2p-lotdev1.betagy.co',
      bffGateway: 'https://bff-gateway-staging.kingmakers.workers.dev',
      userService: 'https://aks-acquisition-test-weu-cluster.kingmakers.tech',
      signalrNotifications: 'https://int-eng.int.kingmakers.tech/api/engagement/signalr/hubs/kingmakers',
    },
    account: {
      accountNumberValidationRegex: '^[0-9]{10}$',
      insufficientBalanceThreshold: 500,
      delimiters: {
        CBEDeposit: [50, 100, 200, 500, 1000],
        CBEWithdrawal: [100, 200, 500, 1000, 5000],
        defaultDeposit: [500, 1000, 5000, 10000, 50000],
        defaultWithdrawal: [5000, 7000, 10000, 50000, 100000],
      },
      enableWithdrawalBankProfile: true,
      bankProfileBlackList: ['master', 'superAgent', 'cashier', 'subAccountPlayer'],
      accountTypes: {
        master: 'J_MASTER',
        superAgent: 'J_SUPER_AG',
        shopOwner: 'J_SHOP_OWN',
        cashier: 'J_CASHIER',
        subAccountPlayer: 'PLYSUB',
      },
      depositRedirect: '',
      profileEditability: 'Off', // Can be 'Off', 'One-Time', 'Always'
      mobilePhoneEditability: false,
      nameSurnameEditability: false,
      loginPrefix: '',
      loginType: 'Username', // Can be 'Username', 'UsernameOrMobile', 'Mobile'
      enableWinNotificationSetting: false,
      checkUserDataUponWithdraw: false,
      enableMessagingReplies: false,
      transactionListDefaultRangeInDays: 7,
      transactionListPageSize: 15,
      enableDepositDefaultRedirection: false,
      enableWithdrawalDefaultRedirection: true,
      mpesaExpressDeposit: {
        pollInterval: 5000,
        pollCountLimit: 6,
      },
      telebirrDeposit: {
        pollInterval: 5000,
        pollCountLimit: 6,
      },
      mobileVerificationCampaign: false,
      paymentsType: 'regular', // Can be 'regular' or 'userJourney',
      depositIncrementAmountValues: [10, 20, 50, 100, 1000],
      withdrawalIncrementAmountValues: [10, 20, 50, 100, 1000],
      paystackDeposit: {
        publicKey: '',
        momoPollInterval: 5000,
        momoPollTimeout: 180000,
        cardRepeatDepositPollInterval: 1000,
        cardRepeatDepositPollAttempts: 5,
      },
      paymentChannel: 'card',
      enableAutoWithdrawalMaximumWithdrawalsAllowed: false,
      kycVerificationFormUrl: '',
      enableWithdrawalNudge: false,
      withdrawalStatus: {
        pollInterval: 5000,
        pollCountLimit: 12,
      },
      depositStatus: {
        pollInterval: 3000,
        pollCountLimit: 10,
      },
      bankProfileStatus: {
        pollInterval: 1000,
        pollCountLimit: 5,
      },
    },
    appInsights: {
      enabled: false,
      instrumentationKey: '',
      androidInstrumentationKey: '',
      samplingPercentage: 0,
    },
    apm: {
      enabled: false,
      serverUrl: '',
      serviceName: '',
      environment: '',
      eventsLimit: 80,
      transactionSampleRate: 0.1,
      disableInstrumentations: [],
      breakdownMetrics: false,
    },
    desktopUrl: '#',
    otp: {
      enableOTPbySms: false,
      otpCodeLength: 6,
      otpResendTimer: 60,
      otpAllowResendWithoutTimer: false,
      enableMobilePhoneVerification: false,
      allowUnconfirmedUsers: false,
      skipRegistrationOTP: false,
    },
    sports: {
      widgetDefaults: {
        landingPage: [
          {
            type: 'liveBetting',
            name: 'Live Now',
            isGrouped: false,
            hasSportFilter: false,
            includeLiveEvents: false,
            numberOfEvents: 15,
            refreshInterval: 6000,
            sortOrder: 1,
            widgetSports: [
              {
                sportName: 'Soccer',
                sportId: 1,
                sortOrder: 1,
                widgetMarkets: [],
              },
            ],
          },
          {
            type: 'liveBettingNew',
            name: 'Live Now New',
            isGrouped: false,
            hasSportFilter: false,
            includeLiveEvents: false,
            numberOfEvents: 5,
            refreshInterval: 6000,
            sortOrder: 2,
            widgetSports: [
              {
                sportName: 'Football',
                sportId: 1,
                sortOrder: 1,
                widgetMarkets: [],
              },
              {
                sportName: 'Football Go',
                sportId: 8,
                sortOrder: 2,
                widgetMarkets: [],
              },
              {
                sportName: 'Basketball',
                sportId: 2,
                sortOrder: 3,
                widgetMarkets: [],
              },
              {
                sportName: 'Tennis',
                sportId: 5,
                sortOrder: 4,
                widgetMarkets: [],
              },
            ],
          },
          {
            type: 'betBuilder',
            name: 'Bet Builder',
            isGrouped: false,
            hasSportFilter: false,
            includeLiveEvents: false,
            numberOfEvents: 15,
            refreshInterval: 60000,
            sortOrder: 3,
            widgetSports: [
              {
                sportName: 'Soccer',
                sportId: 1,
                sortOrder: 1,
                widgetMarkets: [],
              },
            ],
          },
          {
            type: 'mostPopular',
            name: 'Popular',
            isGrouped: true,
            hasSportFilter: false,
            includeLiveEvents: true,
            numberOfEvents: 30,
            refreshInterval: 6000,
            sortOrder: 4,
            widgetSports: [
              {
                sportName: 'Soccer',
                sportId: 1,
                sortOrder: 1,
                widgetMarkets: [],
              },
              {
                sportName: 'Basketball',
                sportId: 2,
                sortOrder: 2,
                widgetMarkets: [],
              },
              {
                sportName: 'Tennis',
                sportId: 5,
                sortOrder: 3,
                widgetMarkets: [],
              },
            ],
          },
          {
            type: 'todayEvents',
            name: 'Today',
            isGrouped: true,
            hasSportFilter: true,
            includeLiveEvents: false,
            numberOfEvents: 20,
            refreshInterval: 60000,
            sortOrder: 5,
            widgetSports: [
              {
                sportName: 'Soccer',
                sportId: 1,
                sortOrder: 1,
                widgetMarkets: [],
              },
              {
                sportName: 'Basketball',
                sportId: 2,
                sortOrder: 2,
                widgetMarkets: [],
              },
              {
                sportName: 'Tennis',
                sportId: 5,
                sortOrder: 3,
                widgetMarkets: [],
              },
            ],
          },
          {
            type: 'oddsBoost',
            name: 'Boosted',
            isGrouped: true,
            hasSportFilter: false,
            includeLiveEvents: false,
            numberOfEvents: 20,
            refreshInterval: 60000,
            sortOrder: 6,
            widgetSports: [
              {
                sportName: 'Soccer',
                sportId: 1,
                sortOrder: 1,
                widgetMarkets: [],
              },
              {
                sportName: 'Basketball',
                sportId: 2,
                sortOrder: 2,
                widgetMarkets: [],
              },
              {
                sportName: 'Tennis',
                sportId: 5,
                sortOrder: 3,
                widgetMarkets: [],
              },
            ],
          },
          {
            type: 'footballGo',
            name: 'FootballGO',
            isGrouped: true,
            hasSportFilter: false,
            includeLiveEvents: false,
            numberOfEvents: 20,
            refreshInterval: 60000,
            sortOrder: 7,
            widgetSports: [
              {
                sportName: 'Soccer',
                sportId: 1,
                sortOrder: 1,
                widgetMarkets: [],
              },
            ],
          },
        ],
      },
      lastMinute: {
        count: 10,
        sportIds: [1, 2, 3, 4, 5, 16],
      },
      live: {
        betRadarWidgetHideIds: [3, 13, 16, 19, 20, 21, 22, 24, 29, 31, 32, 34, 109, 111, 112],
        betGeniusProductName: 'kingmakers',
        newAreaIds: [],
        extraScoresWhiteList: [5, 19, 20, 23],
        marketGroupingDetailsDefaults: [
          {
            sportID: 0,
            marketsCount: 10,
          },
        ],
      },
      statistics: {
        type: 2,
        enabled: true,
        maximumRows: 5,
      },
      coupon: {
        fixedStakes: [100, 200, 500, 1000, 2000],
        oddChangesTimer: 60000,
        liveOddChangesTimer: 30000,
        canBookWhenLoggedIn: true,
        defaultCurrency: 'NGN',
        stakeTaxPercentageToShow: '',
        showBookedBetRetailMessage: true,
        sendBookedCouponCode: false,
        winningsInfoBubble: true,
        marketToParse1X2AsTeamNames: [110],
        flexicutWhitelistedUserTypes: ['user'],
        flexicutTrafficPercent: 100,
        bestSellerBookingCountThreshold: 1000,
      },
      topCompetitions: {
        leagueCount: 6,
        noOfEvents: 1,
      },
      quicklinkItems: [
        {
          label: 'Competitions',
          type: 'competitions',
        },
        {
          label: "Today's Matches",
          type: 'todaysMatches',
        },
        {
          label: 'Boosted',
          type: 'oddBoost',
        },
        {
          label: 'Specials',
          type: 'specials',
        },
        {
          label: 'Outrights',
          type: 'outrights',
        },
      ],
      allowSameMatchSelections: true,
      allowCombinationBets: true,
      freebets: {
        allowFreeBets: false,
        allowOnSingleBets: false,
        allowOnMultipleBets: false,
        allowOnSystemBets: false,
        allowCashout: false,
        freebetVoucherRetrievalstaleTimeInSeconds: 300,
        freebetCMSCallsTimeout: 5000,
        freebetVoucherCallTimeout: 5000,
      },
      flexicut: {
        isNew: true,
      },
      betBuilderMarketTypeID: 10969,
      betBuilderWidgetKey: '52ed49ed-6a62-4409-8744-553618cc2446',
      betBuilderWidgetBrand: 'kingmakers',
      enableCustomMenu: true,
      accumulatorBonus: {
        showProgressionBar: true,
      },
      cashout: {
        blackListedUserTypes: ['J_CASHIER'],
        enabled: true,
        unavailableCodes: [200, 113],
      },
      myBets: {
        couponCount: 20,
        pollLiveDetails: false,
        pollLiveDetailsInterval: 6000,
        url: '',
      },
      bookedBets: {
        enabled: true,
      },
      footballGoId: 8,
      footballGoNewRibbonLeagueIds: [6437799],
      goalscorerShowPlayersCount: 5,
      getSportsListStaleTimeInMinutes: 10,
      hideTabsForSports: [99],
      matchView: {
        marketCountForNonGroupedView: 10,
        marketCountForGroupAutoExpand: 10,
      },
      slowRollout: {
        newLivePagesTrafficPercent: 0,
      },
      betBuilderId: 1595,
      betBuilderRegionId: 212,
      betBuilderName: 'Bet Builder',
      betBuilderSourceId: '6',
      betBuilderOffering: 'BetBuilder',
      totalGoalsMarketId: 160,
    },
    jackpotBets: {
      enabled: false,
      userWhiteListEnabled: false,
      playSourceHeader: 1,
      myBets: {
        couponCount: 20,
      },
      bookedBets: {
        enabled: false,
      },
    },
    xSell: {
      casino: {
        enabled: true,
        showWhenLoggedIn: true,
        showWhenLoggedOut: false,
        whitelist: ['live'],
        screenPosition: 'left',
      },
    },
    messages: {
      defaultPageAmount: 10,
    },
    registration: {
      defaultCountry: 'NGA',
      disableInternationalNumbers: 'true',
      mobileRegex: '^0?[0-9]{10}$',
      deadlineDate: '',
      successRedirectUrl: '/help/getting-started',
      privacyPolicyUrl: '/help/terms-and-conditions',
      termsAndConditionsUrl: '',
      registrationRedirect: '',
      registrationType: 'normal', // can be 'normal', 'quick', 'split', 'userJourney'
      showDNDNotice: true,
      tutorialButtonHighlight: false,
      promoCodeToggleVisibility: false,
    },
    // The applicationServiceMethod means that the menu item will call a method in the application service
    // If you adding a new applicationServiceMethod calls for an item, you need to declare the given method in the application service
    menuDefaults: {
      landingPage: [
        {
          title: 'Football',
          icon: 'sport',
          linkURL: '/sports/football',
        },
        {
          title: 'Live',
          icon: 'live',
          linkURL: '/live',
        },
        {
          title: 'FootballGO',
          icon: 'football-go',
          linkURL: '/sports/football-go',
        },
        {
          title: 'Today',
          icon: 'today',
          linkURL: '/sports/football/todaysMatches',
        },
        {
          title: 'Virtual League',
          icon: 'league',
          linkURL: '/virtual/league',
        },
        {
          title: 'Virtual Offering',
          icon: 'virtual',
          linkURL: '/virtual',
        },
        {
          title: 'Book a Bet',
          icon: 'book-a-bet',
          linkURL: '/book-bet',
        },
        {
          title: 'Odds Less Than',
          icon: 'odds-less-than',
          linkURL: '/odds-less-than',
        },
        {
          title: 'Search',
          icon: 'search',
          linkURL: '/search',
        },
        {
          title: 'Last Minute',
          icon: 'last-minute',
          linkURL: '/last-minute',
        },
        {
          title: 'Quick Bet',
          icon: 'quick-bet',
          linkURL: '/quick-bet',
        },
        {
          title: 'Coupon Check',
          icon: 'coupon-check',
          linkURL: '/coupon-check',
        },
        {
          title: 'News',
          icon: 'news',
          linkURL: 'http://news.betking.com/',
        },
        {
          title: 'Live Chat',
          icon: 'live-chat',
          functionName: 'loadLiveChat',
        },
        {
          title: 'Help',
          icon: 'help',
          linkURL: '/help',
        },
      ],
      sidebarQuicklinks: [],
    },
    correctScoreAreaIds: [537, 1104, 1103, 567, 584, 632, 959],
    live: {
      pollingTimer: 6000,
      useServerSideCoupon: false,
    },
    selectAllMaxItemCount: 50,
    liveChat: {
      liveChatIntegration: 'sfk',
      sfk: {
        buttonId: '5730C000000002C',
        deploymentId: '5720C000000002H',
        organizationId: '00D0C0000000XV5',
      },
    },
    logging: {
      enabled: true,
      minLogLevel: 'Warning',
      endpoints: ['Apm'],
    },
    cmsCacheTTL: 1800,
    cookieLastClick: true,
    cookieFirstClick: false,
    affiliateCookieExpiry: 30,
    fallbackTimer: 10,
    enableDataLayer: true,
    enableOTPOnCall: false,
    enableSlideUps: false,
    enableQuickCouponForPrematch: false,
    enableQuickCouponForLive: true,
    maximumNumberOfToasts: 5,
    canonicalUrl: '',
    nativeApp: {
      appUrl: '',
      appDownloadUrl: '',
      daysOfMaxSessionLength: 7,
    },
    languages: [
      {
        language: 'en',
        languageDescription: 'English',
        locale: 'en-us',
      },
    ],
    social: {
      facebook: 'https://www.facebook.com/Betking-979151908905922',
      twitter: 'https://twitter.com/BetKingNG',
      instagram: 'https://www.instagram.com/betkingng',
      youtube: 'https://www.youtube.com/channel/UCH9QkG2k4oh_kU39IUXjomg',
    },
    registrationRedirect: '',
    footer: {
      showPaymentMethodImage: true,
      leagueOneSponsor: undefined,
      leagueTwoSponsor: undefined,
      showAwards: false,
    },
    virtuals: {
      enabled: true,
      fixedStakes: [20, 100, 200, 500, 1000],
      winningsInfoBubble: true,
      disabledLeaguesForSubAccountPlayers: ['kings-bundliga', 13130, 'kings-insta-live-league', 'kings-insta-liga'],
      eventsTimingsThreshold: 1000,
      breadcrumbNavigationDefaults: {
        title: 'Virtual',
        url: '/virtual',
        uniqueValue: 'virtual',
        subMenu: [
          {
            title: 'Scheduled Leagues',
            url: '/virtual/league',
            uniqueValue: 'virtualScheduled',
            subMenu: [
              {
                title: 'Kings League',
                url: '/virtual/league/kings-league',
                isDefaultGame: true,
                uniqueValue: 'virtualScheduledLeague',
              },
            ],
          },
          {
            title: 'Instant Leagues',
            url: '/virtual/instant/kings-insta-live-league',
            uniqueValue: 'virtualInstant',
            subMenu: [
              {
                title: 'Kings InstaLeague',
                url: '/virtual/instant/kings-insta-live-league',
                isDefaultGame: true,
                uniqueValue: 'virtualInstantKingsLeague',
              },
            ],
          },
          {
            title: 'Games',
            url: '/virtual/games',
            uniqueValue: 'virtualGames',
            subMenu: [
              {
                title: 'Spin2Win',
                url: '/virtual/games/spin-2-win',
                isDefaultGame: true,
                uniqueValue: 'virtualGamesSpin2Win',
              },
            ],
          },
          {
            title: 'Racing',
            url: '/virtual/racing',
            uniqueValue: 'virtualRacing',
            subMenu: [
              {
                title: 'Dog Racing',
                url: '/virtual/racing/greyhounds-6',
                isDefaultGame: true,
                uniqueValue: 'virtualRacingGreyhounds',
              },
            ],
          },
        ],
      },
      menuSectionsDefaults: [
        {
          sectionName: 'Scheduled Football Leagues',
          icon: 'soccer',
          sectionGames: [
            {
              game: 'kings-league',
              gameTitle: '',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/nygkg2t2/kings-league.svg',
              isNew: false,
              showLoginDialog: false,
              showDemoCTA: false,
              isOnDemandGame: false,
            },
            {
              game: 'kings-liga',
              gameTitle: '',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/dd2m4zwg/kings-liga.svg',
              isNew: false,
              showLoginDialog: false,
              showDemoCTA: false,
              isOnDemandGame: false,
            },
            {
              game: 'kings-italiano',
              gameTitle: '',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/akohgqvy/kings-italiano.svg',
              isNew: false,
              showLoginDialog: false,
              showDemoCTA: false,
              isOnDemandGame: false,
            },
            {
              game: 'kings-bundliga',
              gameTitle: '',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/ai3d4zdv/kings-bundliga.svg',
              isNew: true,
              showLoginDialog: false,
              showDemoCTA: false,
              isOnDemandGame: false,
            },
          ],
        },
        {
          sectionName: 'Instant Football Leagues',
          icon: 'soccer',
          sectionGames: [
            {
              game: 'kings-insta-live-league',
              gameTitle: 'KINGS INSTALEAGUE',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/ehyass1e/kings-instaleague-logo-full.svg',
              isNew: false,
              showLoginDialog: true,
              showDemoCTA: false,
              isOnDemandGame: true,
            },
            {
              game: 'kings-insta-liga',
              gameTitle: 'KINGS INSTALIGA',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/vlnk5whe/kings-instaliga-logo-full.svg',
              isNew: false,
              showLoginDialog: true,
              showDemoCTA: false,
              isOnDemandGame: true,
            },
          ],
        },
      ],
      lobbyContentDefaults: {
        sections: [
          {
            title: 'Scheduled Football Leagues',
            subTitle: 'Kick-off is every 3 minutes',
            isEnabled: true,
            isNew: false,
            jackpot: 'kingmaker',
            jackpotLabel: 'Virtual KingMaker Jackpot',
            jackpotDropFrequencyText: 'Won on average every',
            jackpotClickUrl: '/latest-jackpots',
            subSections: [
              {
                title: '',
                gamesPerRow: 2,
                games: [
                  {
                    code: 'kings-league',
                    gameType: 'scheduled-league',
                    providerId: '2',
                    title: 'Kings League',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/m1sjz5mh/kings-league.svg',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: true,
                    showCountdown: true,
                    showLoginDialog: false,
                  },
                  {
                    code: 'kings-liga',
                    gameType: 'scheduled-league',
                    providerId: '3',
                    title: 'Kings Liga',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/w2cfrk5m/kings-liga.svg',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: true,
                    showCountdown: true,
                    showLoginDialog: false,
                  },
                  {
                    code: 'kings-italiano',
                    gameType: 'scheduled-league',
                    providerId: '4',
                    title: 'Kings Italiano',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/32emd5cb/kings-italiano.svg',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: true,
                    showCountdown: true,
                    showLoginDialog: false,
                  },
                  {
                    code: 'kings-bundliga',
                    gameType: 'scheduled-league',
                    providerId: '13130',
                    title: 'Kings Bundliga',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/vcpkvsn0/kings-bundliga.svg',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: true,
                    showCountdown: true,
                    showLoginDialog: false,
                  },
                ],
              },
            ],
            ctaLabel: '',
            ctaButtonText: '',
            ctaClickUrl: '',
          },
          {
            title: 'Instant Football Leagues',
            subTitle: 'Play when you want!',
            isEnabled: true,
            isNew: true,
            jackpot: '',
            jackpotLabel: '',
            jackpotDropFrequencyText: '',
            jackpotClickUrl: '',
            subSections: [
              {
                title: '',
                gamesPerRow: 2,
                games: [
                  {
                    code: 'kings-insta-live-league',
                    gameType: 'instant-league',
                    providerId: 'GROLeagueEngland',
                    title: 'Kings InstaLeague',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/rpuokqjm/kings-instaleague-58x54.svg',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: true,
                    showCountdown: false,
                    showLoginDialog: true,
                  },
                  {
                    code: 'kings-insta-liga',
                    gameType: 'instant-league',
                    providerId: 'GROLeagueSpain',
                    title: 'Kings InstaLiga',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/dzfd4y5d/kings-instaliga-58x54.svg',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: true,
                    showCountdown: false,
                    showLoginDialog: true,
                  },
                  {
                    code: 'kings-insta-italiano',
                    gameType: 'instant-league',
                    providerId: 'GROLeagueItaly',
                    title: 'Kings InstaItaliano',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/eckabeq4/kings-instaseriea-58x54.svg',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: true,
                    showCountdown: false,
                    showLoginDialog: true,
                  },
                  {
                    code: 'kings-insta-bundliga',
                    gameType: 'instant-league',
                    providerId: 'GROLeagueGermany',
                    title: 'Kings InstaBundliga',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/kx4ncm0r/kings-instabundliga-58x54.svg',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: true,
                    showCountdown: false,
                    showLoginDialog: true,
                  },
                ],
              },
            ],
            ctaLabel: '',
            ctaButtonText: '',
            ctaClickUrl: '',
          },
          {
            title: 'More Games',
            subTitle: 'Try our racing and number games!',
            isEnabled: true,
            isNew: false,
            jackpot: '',
            jackpotLabel: '',
            jackpotDropFrequencyText: '',
            jackpotClickUrl: '',
            subSections: [
              {
                title: 'Number Games',
                gamesPerRow: 3,
                games: [
                  {
                    code: 'spin-2-win',
                    gameType: 'other',
                    providerId: '',
                    title: 'Spin 2 Win',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/nvyplvy2/spin-2-win.png',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: false,
                    showCountdown: false,
                    showLoginDialog: false,
                  },
                  {
                    code: 'keno',
                    gameType: 'other',
                    providerId: '',
                    title: 'Keno',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/vxun0wrv/keno.png',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: false,
                    showCountdown: false,
                    showLoginDialog: false,
                  },
                  {
                    code: 'color-color',
                    gameType: 'other',
                    providerId: '',
                    title: 'Color Color',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/vfenyd1m/colour-colour.png',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: false,
                    showCountdown: false,
                    showLoginDialog: false,
                  },
                ],
              },
              {
                title: 'Races',
                gamesPerRow: 3,
                games: [
                  {
                    code: 'greyhounds-6',
                    gameType: 'other',
                    providerId: '',
                    title: 'Dog Racing [GB]',
                    logoUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/edomjwji/dog-racing.png',
                    isEnabled: true,
                    isNew: false,
                    showPlayButton: false,
                    showCountdown: false,
                    showLoginDialog: false,
                  },
                ],
              },
            ],
            ctaLabel: 'Like our Virtual Games? Try our new Casino!',
            ctaButtonText: 'Casino',
            ctaClickUrl: '/casino',
          },
        ],
      },
      scheduledLeague: {
        enabled: false,
        assetsUrl: '/assets/mvs/',
        mountingPoint: '/virtual/league',
        defaultLeagueId: 2,
        showLivePlayCTA: false,
        showStats: false,
        gameTime: 90,
        playoutTimeMs: 30000,
        countDownHeartBeatMs: 5000,
        minutesBetweenWeeks: 3,
        inPlayResultsDurationMs: 3000,
        recentBetsStorageKey: 'mvsRecentBets',
        defaultCurrency: 'NGN',
        allowSameMatchSelections: true,
        allowCombinationBets: true,
        showMinOddAsMissingOdd: false,
        useVirtualsNavbar: false,
        freebets: {
          allowFreeBets: true,
          allowOnSingleBets: true,
          allowOnMultipleBets: true,
          allowOnSystemBets: false,
          freebetVoucherRetrievalstaleTimeInSeconds: 300,
          freebetCMSCallsTimeout: 30000,
          freebetVoucherCallTimeout: 30000,
        },
        accumulatorBonus: {
          showProgressionBar: true,
          whiteList: ['league', 'kings-league', 'kings-italiano', 'kings-liga', 'kings-bundliga'],
        },
        categories: {
          '2': {
            url: 'kings-league',
            theme: {
              themeColorPrimary: '#2662d9',
              themeColorSecondary: '#121d73',
              textColor: '#fff',
            },
          },
          '3': {
            url: 'kings-liga',
            theme: {
              themeColorPrimary: '#E51C3E',
              themeColorSecondary: '#930241',
              textColor: '#fff',
            },
          },
          '4': {
            url: 'kings-italiano',
            theme: {
              themeColorPrimary: '#269dd9',
              themeColorSecondary: '#2863B5',
              textColor: '#fff',
            },
          },
          '13110': {
            url: 'kings-italiano',
            theme: {
              themeColorPrimary: '#269dd9',
              themeColorSecondary: '#2863B5',
              textColor: '#fff',
            },
          },
          '14102': {
            url: 'kings-euro',
            theme: 'champions',
          },
          '13130': {
            url: 'kings-bundliga',
            theme: {
              themeColorPrimary: '#E51C3E',
              themeColorSecondary: '#930241',
              textColor: '#fff',
            },
          },
        },
        winnerMarketId: 3,
      },
      instantLeague: {
        enabled: false,
        mountingPoint: '/virtual/instant',
        defaultLeagueId: 'GROLeagueEngland',
        defaultCurrency: 'NGN',
        allowSameMatchSelections: true,
        allowCombinationBets: true,
        recentBetsStorageKey: 'mvsInstantRecentBets',
        winningsStorageKey: 'mvsInstantWinningsData',
        webSocketActiveUrls: [
          'instant',
          'kings-insta-live-league',
          'kings-insta-liga',
          'my-bets',
          'virtuals-bet-search',
          'bet-search',
          'instant-coupon',
        ],
        userDataStorageKey: 'mvsInstantUserData',
        couponDataStorageKey: 'mvsInstantCouponData',
        couponExtraDataStorageKey: 'mvsInstantCouponExtraData',
        lastEventAndCategoryStorageKey: 'mvsLastEventAndSelectedCategory',
        numberOfOpenInPlayFixtures: 5,
        sessionSynchronizeInterval: 120000,
        videoSlotsStartingTime: [0, 15, 30, 45, 60, 75],
        showMinOddAsMissingOdd: true,
        useVirtualsNavbar: false,
        accumulatorBonus: {
          lastPercentageItemShown: 40,
          showProgressionBar: true,
          whiteList: ['instant', 'kings-insta-live-league', 'kings-insta-liga'],
        },
        categories: {
          GROLeagueEngland: {
            url: 'kings-insta-live-league',
            theme: {
              themeColorPrimary: '#2662d9',
              themeColorSecondary: '#121d73',
              textColor: '#fff',
            },
            name: 'InstaLeague',
            displayOrder: 1,
          },
          GROLeagueSpain: {
            url: 'kings-insta-live-league',
            theme: {
              themeColorPrimary: '#ffc400',
              themeColorSecondary: '#CC6D00',
              textColor: '#fff',
            },
            name: 'InstaLiga',
            displayOrder: 2,
          },
        },
        webSocketConfig: {
          host: 'wss://vi-proxy.betking.com/vs',
          basePath: '/api/external/v2',
          reconnection: {
            delay: 5000,
          },
          timeout: 60000,
          debug: true,
        },
        winnerMarketId: 'Match_Result',
      },
      tournaments: {
        mountingPoint: '/virtual/football/tournaments',
      },
      globalBet: {
        enabled: false,
        enabledGames: [],
      },
      goldenRace: {
        enabled: false,
        enabledGames: [],
        disabledGamesForSubAccountPlayers: ['keno', 'spin-2-win', 'dog-racing'],
        launcherUrl: '',
        phk: '',
        iframeDisplayTimeout: 2000,
      },
      showBetslipLoginDialog: true,
      jackpot: {
        jackpotWonTextDuration: 3500,
        jackpotDropDuration: 2500,
        jackpotDropValueTransition: 1000,
        jackpotIncrementValueTransition: 2000,
        jackpotApiCallInterval: 60000,
        updatesPerSec: 0.01,
        jackpotsRotationInterval: 180000,
        enableJackpotCategories: [2, 3, 4, 13130, 13306, 13307, 13308, 13309],
        dukeJackpotId: 5,
        kingmakerJackpotId: 6,
        jackpotShortName: 'Jackpot',
        jackpotLongName: 'Jackpot',
        jackpotPluralName: 'Jackpots',
      },
      enableWebSocketConnection: false,
      showExcludingTaxLabels: false,
      cashout: {
        enabled: false,
        staleTimeOfCashoutValuesInSeconds: 15,
        timeoutOfCashoutResponseModalInSeconds: 10,
      },
      myBets: {
        couponCount: 20,
        pollLiveDetails: false,
        pollLiveDetailsInterval: 6000,
        instantLeagueDaysToSearch: 21,
      },
      bookedBets: {
        enabled: false,
      },
      defaultGames: {
        games: 'spin-2-win',
        racing: 'greyhounds-6',
      },
    },
    geoAccessControl: {
      accessBlockingEnabled: false,
      tldRedirectionEnabled: false,
      siteCountryCode: '',
    },
    geolocationRedirect: {}, // TODO: Phase this out in favour of the newer 'geoAccessControl'
    accessDownloadPageEnabled: false,
    strapi: {
      timeout: 30000,
    },
    header: {
      productSwitcher: {
        blacklist: ['/coupon', '/instant-coupon', '/my-bets', '/account', '/message-center', '/help', '/menu'],
      },
      appBar: {
        blacklist: ['/account/login', '/account/registration', '/register'],
      },
    },
    abTesting: {
      config: {
        enabled: false,
        timeout: 1000,
      },
      activeTests: {
        // Declare the list of active tests here. Example:
        // <testName>: {
        //   testId: '',
        //   defaultValue: '',
        //   events: {
        //     <eventName>: '<eventId>',
        //   },
        // }
      },
    },
  },
  appBanner: {
    androidEnabled: true,
    iosEnabled: true,
    linkToApp: 'https://app.adjust.com/19ypu1ab?campaign=home-page-widget',
  },
  signalrNotificationsEnabled: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
