<div class="event-container">
  <div class="event-wrapper" [ngClass]="{'no-padding': !showDetails, 'last': last, 'new-live-widget': isNewLiveWidget}">
    <app-event-tournament-details [event]="event" [sportId]="sportId" [showOddCount]="false" *ngIf="showDetails" [isNewLiveWidget]="isNewLiveWidget">
    </app-event-tournament-details>
    <div class="event" [class.is-live]="event.eventCategory === 'L'">
      <div class="event-details">
        <app-event-details [event]="event" [showDate]="showDate" *ngIf="showDetails" [isNewLiveWidget]="isNewLiveWidget"
          (click)="navigateToMatch(event.id, event.name, event.eventCategory === 'L')"></app-event-details>
      </div>
      <div class="event-odds">
        <app-spread-line-selector [id]="index" *ngIf="groupedMarkets?.length > 0" [button]="true" [selectedOption]="selectedOption" [event]="event" [options]="groupedMarkets" (optionSelected)="handleOptionSelected($event)"
          placeholder="Change"></app-spread-line-selector>
        <app-odd-group [haveSpreadSelector]="groupedMarkets?.length > 0" [odds]="getOdds()" [eventLocation]="eventLocation" [itemIndex]="itemIndex" [isLive]="event.eventCategory === 'L'"></app-odd-group>
      </div>
    </div>
  </div>
  <div class="statistics"
    [@expandCollapse]="{ value: (statisticsQuery.expandedStatistics$ | async) ? 'expanded' : 'collapsed'}">
    <app-statistics *ngIf="event.externalId === (statisticsQuery.selectedExternalId$ | async)"
      [externalId]="event.externalId"></app-statistics>
  </div>
</div>
