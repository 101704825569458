<div appAdjustTabOnSelected>
  <button
    class="widget-tab"
    *ngFor="let widgetTab of tabsData"
    [class.selected]="selectedTab === widgetTab[selectedIdentifier]"
    i18n
    (click)="onChange(widgetTab)"
  >
    <i *ngIf="widgetTab.type === Quicklinks.OddBoost" class="odds-boost-badge"></i>
    <span class="btn-text" *ngIf="widgetTab.isNew">
      <span class="indicator">NEW</span>
    </span>
    <span *ngIf="widgetTab.iconUrl" class="widget-icon" style="background-image: url('{{ widgetTab.iconUrl }}')"></span>
    <span class="widget-name">
      {{ widgetTab | findByProperty : labelIdentifier }}
    </span>
  </button>
</div>
